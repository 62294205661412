import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import * as myGlobals from '../globals';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {AppinfoService} from './appinfo.service';

@Injectable({
  providedIn: 'root'
})
export class ServerVersionService {

  events: any;
  serverVersion;
  userData;
  page_strings: any;

  constructor(
      private alertCtrl: AlertController,
      private storage: Storage,
      private appInfoService: AppinfoService
  ) {
    this.page_strings = {
      update_message_title: myGlobals.welcome_update_message_title,
      update_message: myGlobals.welcome_update_message,
      ok_button: myGlobals.app_ok_button
    };
    this.events = new Subject();
    this.serverVersion = 'UNKNOWN';
  }

  startWatching() {
    this.setVersionCheckTimer();
  }

  setVersionCheckTimer() {
    setTimeout(() => {
      this.checkAppVersion();
    }, myGlobals.welcome_check_version_timeout_ms);
  }

  checkAppVersion() {
    this.storage.get('userData')
        .then((val) => {
          if (val) {
            const localAppVersion = this.getLocalAppVersion();
            this.getServerVersion(val)
                .then(() => {
                  const serverVersion = this.serverVersion;
                  if ((serverVersion !== 'UNKNOWN') && (localAppVersion !== serverVersion)) {
                    this.promptToDownloadNewVersion();
                  } else {
                    this.setVersionCheckTimer();
                  }
                })
                .catch(() => {
                  this.setVersionCheckTimer();
                });
          } else {
            console.log('No userData so just reset timer');
            this.setVersionCheckTimer();
          }
        })
        .catch(() => {
          console.log('Error getting userData so just reset timer');
          this.setVersionCheckTimer();
        });
  }

  getLocalAppVersion() {
    return myGlobals.app_version_number;
  }

  getServerVersion(userData) {
    this.serverVersion = 'UNKNOWN';
    const promise = new Promise((resolve, reject) => {
      this.appInfoService.getAll(userData)
          .then(api_results => {
            this.serverVersion = api_results[0].app_version;
            this.events.next(moment().format('MMM DD YYYY, h:mm:ss a'));
            resolve();
          })
          .catch((err) => {
            console.log('Could not get appinfo.' + err);
            resolve();
          });
    });
    return promise;
  }

  promptToDownloadNewVersion() {
    this.alertCtrl.create({
      header: this.page_strings.update_message_title,
      message: this.page_strings.update_message,
      backdropDismiss: false,
      buttons: [
        {
          text: this.page_strings.ok_button,
          handler: () => {
            // window.location.reload()
            window.location.href = 'https://techapp2.zochnet.com';
          }
        }
      ]
    }).then((alert) => alert.present());
  }
}
