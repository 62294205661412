import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Subject} from 'rxjs';

export enum ConnectionStatusEnum {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  currentStatus: any;
  public events: any;

  constructor(
      public http: HttpClient
  ) {
    this.events = new Subject();

    this.currentStatus = ConnectionStatusEnum.Offline;
    if (navigator.onLine) {
      this.currentStatus = ConnectionStatusEnum.Online;
    }
  }

  startWatching() {
    const self = this;
    window.addEventListener('online', (e) => {
      console.log('went online');
      self.currentStatus = ConnectionStatusEnum.Online;
      self.events.next(self.currentStatus);
    }, false);

    window.addEventListener('offline', (e) => {
      console.log('went offline');
      self.currentStatus = ConnectionStatusEnum.Offline;
      self.events.next(self.currentStatus);
    }, false);
  }
}
