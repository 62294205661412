import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../globals';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppinfoService {

  url: string;
  timeout;

  constructor(public http: HttpClient) {
    // console.log('Hello AppinfoProvider Provider');
    this.url = myGlobals.app_api_base_url;
    this.timeout =  myGlobals.app_api_timeout_ms;
  }

  private _getAll(userData) {
    const body = JSON.stringify({userData});
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.url + '/apiv1/appinfo', body, {headers}).pipe(timeout(this.timeout));
  }

  getAll(userData) {
    const promise = new Promise((resolve, reject) => {
      this._getAll(userData)
          .subscribe(api_results => {
                const retData = this.getRetDataFromApiResults(api_results);
                if (retData != null) {
                  // console.log("ALL_GOOD");
                  resolve(retData);
                } else {
                  console.log('APP_ERROR');
                  reject();
                }
              },
              error2 => {
                console.log('NETWORK_ERROR');
                reject();
              }
          );
    });

    return promise;
  }

  getRetDataFromApiResults(api_results: any) {
    const resultObj: any = api_results;
    if (resultObj == null || resultObj.retData == null || (resultObj.error && resultObj.error.text)) {
      return null;
    } else {
      return resultObj.retData;
    }
  }

}
