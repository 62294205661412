import {Component, OnInit} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {NetworkService} from './services/network.service';
import {ServerVersionService} from './services/server-version.service';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public networkService: NetworkService,
    private platform: Platform,
    public serverVersionService: ServerVersionService,
    private swUpdate: SwUpdate,
    private alertCtrl: AlertController
  ) {
    this.platform.ready().then(() => {
      this.networkService.startWatching();
      this.serverVersionService.startWatching();
    });
    window.addEventListener('load', () => {
      window.history.pushState(null, null, window.location.href);
    });

    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.updatePrompt();
      });
    }
  }

  updateConfirmed() {
    // window.location.reload();
    window.location.href = 'https://techapp2.zochnet.com';
  }

  updatePrompt() {
    this.alertCtrl.create({
      header: 'New Version Available',
      message: 'Click OK to update the software to the latest version',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.updateConfirmed();
          }
        }
      ]
    }).then(alert => alert.present());
  }
}
