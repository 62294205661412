//
// ===== File globals.ts
//
'use strict';

declare var appVersion: string;
declare var appVersionMinus1: string;
declare var appVersionMinus2: string;

export const app_lat = 30.3012427;
export const app_lon = -96.9130256;
export const app_logo = 'assets/imgs/logo.png';
export const app_api_base_url = 'https://cors.zochnet.com/http://uvaldeapi.zochnet.com';
export const app_socket_io_url = 'https://cors.zochnet.com/https://socket.zochnet.com';
export const app_version_number: string = appVersion;
export const app_version_number_minus1: string = appVersionMinus1;
export const app_version_number_minus2: string = appVersionMinus2;
export const app_version: string = 'Version ' + app_version_number;
export const app_version_minus1: string = 'Version ' + app_version_number_minus1;
export const app_version_minus2: string = 'Version ' + app_version_number_minus2;
export const app_version_last_checked = 'Version Last Checked:';
export const app_copyright = 'Copyright ZochNet';
export const app_copyright_start_year = 2018;
export const app_title = 'ISP Mobile';
export const app_description = 'Your mobile ISP solution';
export const app_api_timeout_ms = 30000;
export const app_are_you_sure_message = 'Are you sure?';
export const app_save_button = 'Save';
export const app_cancel_button = 'Cancel';
export const app_delete_button = 'Delete';
export const app_yes_button = 'Yes';
export const app_ok_button = 'OK';
export const app_close_button = 'Close';
export const app_old_ios_version = 'This device has an old iOS version that is not supported.  You must have version 11.3 or later.  ' +
    'Please update your iOS version and try again.  Your version is: ';
export const app_form_error = 'Please correct your inputs and try again.';
export const app_accuracy_required = 10;
export const app_form_error_accuracy: string = 'GPS accuracy too low.  Must be less than ' + app_accuracy_required + ' meters.';
export const app_error_message_title = 'Error';
export const app_error_loading_data = 'There was an error loading data.  Press Reload Data to try again.';
export const app_success_uploading_data = 'Your data was uploaded successfully.';
export const app_success_deleting_data = 'Your data was deleted successfully.';
export const app_error_uploading_pending_data = 'Unable to upload pending data at this time.  Data will upload automatically when a ' +
    'connection is available, or you may try again.';
export const app_error_uploading_data_queued = 'Unable to upload data at this time.  Your data was saved locally and will upload ' +
    'automatically when a connection is available.';
export const app_error_uploading_data_try_again = 'An error occurred while uploading data.  Please try again.';
export const app_confirm_delete_message = 'Delete this item?';
export const app_using_cached_data = 'Unable to load all data from server, so using cached data.';
export const app_offline_data_header = 'Data Pending Upload';
export const app_offline_data_message = ' items are pending upload.  Once you are reconnected to the network, your data will be ' +
    'uploaded automatically.  Or you can click the button below to retry.';
export const app_offline_data_retry_button = 'Retry Upload';
export const app_delete_error_need_online = 'Unable to delete item.  Please try again when connected to the Internet';
export const app_load_photos_error_need_online = 'Unable to load photos when disconnected.  Please try again when connected to the ' +
    'Internet';

export const welcome_heading1 = 'Welcome to ISP Mobile';
export const welcome_heading2 = 'Your mobile ISP solution';
export const welcome_login = 'Login';
export const welcome_reload_button = 'Reload';
export const welcome_install_button = 'Install';
export const welcome_add_button = 'Add';
export const welcome_upgrade_message = 'New update available! Click Reload to load the latest changes.';
export const welcome_a2hs_message = 'Add to Homescreen?';
export const welcome_check_version_timeout_ms = 60000;
export const welcome_update_message_title = 'Update Available';
export const welcome_update_message = 'A required application update is ready to download.  Please click OK to update.';

export const login_username = 'Username';
export const login_password = 'Password';
export const login_login = 'Login';

export const about_title = 'About';
export const about_logout_button = 'Logout';
export const about_check_for_updates_button = 'Check for Updates';
export const about_network_status_label = 'Network Status:';
export const about_lat_lon_label = 'Lat/Lon:';
export const about_logged_in_as_label = 'Logged in as:';

export const about_what_is_new_button = 'What\'s New?';
export const about_confirm_logout_message = 'You will need to re-enter your username and password if you logout.  ' +
    'Are you sure you want to logout?';

export const whats_new_text: string =
    '<h2>' + app_version + '</h2> \
<ul> \
<li>Update missing icons, broken by Google.</li> \
</ul> \
    <h2>' + app_version_minus1 + '</h2> \
<ul> \
<li>Update customer icons.</li> \
</ul> \
<h2>' + app_version_minus2 + '</h2> \
<ul> \
<li>Make Notes field multi-line in poles, cabs, taps and encs.</li> \
</ul> \
';
